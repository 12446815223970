import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { SystemService } from './system.service';

export enum ErrorSeverity {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  CRITICAL = 'CRITICAL',
  UNKNOWN = 'UNKNOWN'
}

export enum ErrorKnown {
  FS_QUERY_REQUIRES_AN_INDEX = 'FS_QUERY_REQUIRES_AN_INDEX',
  FAILED_TO_FETCH_DYNAMICALLY_IMPORTED_MODULE = 'FAILED_TO_FETCH_DYNAMICALLY_IMPORTED_MODULE',
}

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  knownErrors: ErrorKnown[] = [];
  unknownErrors: {
    err: any,
    message: string | null
  }[] = [];

  constructor(
    private utilsService: UtilsService,
    private sysService: SystemService
  ) { }

  async onUnknownErrorOccured(err: any) {
    const message = err.message || null;
    if (!message) return;

    const found = this.unknownErrors.find(e => e.err.message === err.message);

    this.unknownErrors.push({ err, message });
    if (!found) {
      await this.utilsService.logError(`Error message: ${message}\n\nstack:\n${err.stack}`, ErrorSeverity.UNKNOWN);
    }
    
  }

  async onKnownErrorOccured(err: ErrorKnown, errMessage: string) {
    this.knownErrors.push(err);

    if (err === ErrorKnown.FS_QUERY_REQUIRES_AN_INDEX) {
      await this.utilsService.logError(errMessage, ErrorSeverity.CRITICAL);
    } else if (err === ErrorKnown.FAILED_TO_FETCH_DYNAMICALLY_IMPORTED_MODULE) {
      this.sysService.newAppVersion$.next(true);
    }
  }

}
