import { APP_ID, ErrorHandler, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { getApp,initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideFunctions,getFunctions, connectFunctionsEmulator } from '@angular/fire/functions';
import { initializeAppCheck, provideAppCheck, ReCaptchaV3Provider } from '@angular/fire/app-check';
import { AppRoutingModule } from './app-routing.module';
import { MessageService } from 'primeng/api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastModule } from 'primeng/toast';
import { BlankLayoutComponent } from './shared/layouts/blank-layout/blank-layout.component';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import localeEn from '@angular/common/locales/en';
import localeSk from '@angular/common/locales/sk';
import { BlockUIModule } from 'primeng/blockui';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireModule } from '@angular/fire/compat';
import { UserNameOrEmailPipe } from './shared/pipes/user-name-or-email.pipe';
import { PaymentGatePaymentStatusComponent } from './pages/payment-gate-redirect/payment-gate-payment-status/payment-gate-status.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { NoInternetBarComponent } from './shared/components/no-internet-bar/no-internet-bar.component';
import { LocalizedDatePipe } from './shared/pipes/localized-date.pipe';
import { LocationNamePipe } from './shared/pipes/location-name.pipe';
import { ErrorKnown, ErrorService } from './shared/services/error.service';
// import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

registerLocaleData(localeCs);
registerLocaleData(localeEn);
registerLocaleData(localeSk);



if (!environment.production || (environment.production && environment.local)) {
  (<any>window).FIREBASE_APPCHECK_DEBUG_TOKEN  = environment.firebase.appCheck.debug;
}

declare global {
  interface Date {
    addDays: (days: number) => Date;
    addSeconds: (seconds: number) => Date;
    addUTCDays: (days: number) => Date;
    addUTCMiliSeconds: (miliSeconds: number) => Date;
    toUTCLikeDate: () => Date;
    localDateToUTC: () => Date;
  }
}
Date.prototype.addDays = function(days: number) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}
Date.prototype.addSeconds = function(seconds: number) {
  var date = new Date(this.valueOf());
  date.setSeconds(date.getSeconds() + seconds);
  return date;
}
Date.prototype.addUTCDays = function(days: number) {
  var date = new Date(this.valueOf());
  date.setUTCDate(date.getUTCDate() + days);
  // var date = new Date(Date.UTC(this.getUTCFullYear(), this.getUTCMonth(), this.getUTCDate() + days, this.getUTCHours(), this.getUTCMinutes(), this.getUTCSeconds()));
  return date;
}
Date.prototype.addUTCMiliSeconds = function(miliSeconds: number) {
  var date = new Date(this.valueOf());
  date.setUTCMilliseconds(date.getUTCMilliseconds() + miliSeconds);
  return date;
}
Date.prototype.toUTCLikeDate = function() {
  // Vytvoření nového Date objektu, který reprezentuje lokální čas jako UTC
  const utcLikeDate = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate(), this.getHours(), this.getMinutes(), this.getSeconds()));
  return utcLikeDate;
}
Date.prototype.localDateToUTC = function() {
  return new Date(this.getTime() + this.getTimezoneOffset() * 60 * 1000);
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@Injectable()
export class CustomGlobalErrorHandler implements ErrorHandler {
  constructor(
    private errorService: ErrorService
  ) {}
  async handleError(error: Error) {
    console.error(error);

    if (environment.local) return;

    if (error.message.includes('The query requires an index')) {
      this.errorService.onKnownErrorOccured(ErrorKnown.FS_QUERY_REQUIRES_AN_INDEX, error.message);
    } else if (
      error.message.includes('Failed to fetch dynamically imported module') ||
      error.message.includes('error loading dynamically imported module')
    ) {
      this.errorService.onKnownErrorOccured(ErrorKnown.FAILED_TO_FETCH_DYNAMICALLY_IMPORTED_MODULE, error.message);
    } else if (
      error.message.includes('NG0100') ||
      error.message.includes("offsetParent")
    ) {
      // do nothing
    } else {
      this.errorService.onUnknownErrorOccured(error);
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    BlankLayoutComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase.config),
    AngularFirestoreModule, // move to where it is needed?
    provideFirebaseApp(() => initializeApp(environment.firebase.config)),
    // provideAppCheck(() => initializeAppCheck(getApp(), {
    //   provider: new ReCaptchaV3Provider(environment.firebase.appCheck.recaptcha3SiteKey),
    //   isTokenAutoRefreshEnabled: environment.firebase.appCheck.isTokenAutoRefreshEnabled,
    // })),
    provideAuth(() => getAuth()),
    provideFunctions(() => {
      const functions = getFunctions(getApp(), 'europe-west1');
      if (environment.local) {
        connectFunctionsEmulator(functions, 'localhost', 5002);
      }
      return functions;
    }),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    NoInternetBarComponent,

    /* PRIMENG */
    ToastModule,
    BlockUIModule,
    PaymentGatePaymentStatusComponent
  ],
  providers: [
    MessageService,
    CurrencyPipe,
    UserNameOrEmailPipe,
    LocalizedDatePipe,
    LocationNamePipe,

    { provide: APP_ID, useValue: 'Lektory_mainApp' },
    { provide: LOCALE_ID, useValue: 'cs-CZ' },
    { provide: ErrorHandler, useClass: CustomGlobalErrorHandler}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

